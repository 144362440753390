// import React from 'react';
// import Device from './components/pages/Dashboard';
// import Layout from './components/pages/Layout';


// const App = () => {
  

//   return (
//     <Layout>

//       <Device/>
//     </Layout>
//   );
// };

// export default App;



// // import React, { useState, useEffect } from 'react';
// // import DeviceEmulator from 'react-device-emulator';
// // import 'react-device-emulator/lib/styles/style.css';

// // const App = () => {
// //   const [deviceDetails, setDeviceDetails] = useState(null);

// //   useEffect(() => {
// //     const fetchDeviceDetails = async () => {
// //       try {
// //         const response = await fetch('http://localhost:4040/api/device-details');
// //         const data = await response.json();
// //         setDeviceDetails(data);
// //       } catch (error) {
// //         console.error('Error fetching device details:', error);
// //       }
// //     };

// //     fetchDeviceDetails();
// //   }, []); 


// //   if (!deviceDetails) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <div style={{
// //       display: 'flex',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       height: '100vh',
// //       backgroundColor: '#f5f5f5'
// //     }}>
// //       {/* Mobile Emulator with the custom fields displayed */}
// //       <div style={{
// //         display: 'flex',
// //         justifyContent: 'center',
// //         alignItems: 'center',
// //         flexDirection: 'column',
// //       }}>
// //         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false}>
// //           <div style={{
// //             padding: '20px',
// //             textAlign: 'left',
// //             backgroundColor: '#fff',
// //             borderRadius: '8px',
// //             boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //             width: '100%',
// //           }}>
// //             <h2>Device Information</h2>
// //             <p><strong>IMEI Number:</strong> {deviceDetails.imeiNumber}</p>
// //             <p><strong>Device Name:</strong> {deviceDetails.deviceName}</p>
// //             <p><strong>Device Model:</strong> {deviceDetails.deviceModel}</p>
// //             <p><strong>Photos Count:</strong> {deviceDetails.photosCount}</p>
// //             <p><strong>Audio Files Count:</strong> {deviceDetails.audioFilesCount}</p>
// //             <p><strong>Videos Count:</strong> {deviceDetails.videosCount}</p>
// //             <p><strong>Installed Apps Count:</strong> {deviceDetails.installedAppsCount}</p>
// //             <p><strong>Android Version:</strong> {deviceDetails.androidVersion}</p>
// //             <p><strong>Location:</strong> {deviceDetails.location}</p>
// //           </div>
// //         </DeviceEmulator>
// //       </div>
// //     </div>
// //   );
// // };

// // export default App;



// // import React, { useState, useEffect } from 'react';
// // import DeviceEmulator from 'react-device-emulator';
// // import 'react-device-emulator/lib/styles/style.css';

// // // HomeScreen component to show initial content
// // const HomeScreen = ({ onOpenApp }) => (
// //   <div style={{
// //     padding: '20px',
// //     textAlign: 'center',
// //     backgroundColor: '#fff',
// //     borderRadius: '8px',
// //     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //     width: '100%',
// //     height: '100vh',
// //     display: 'flex',
// //     flexDirection: 'column',
// //     alignItems: 'center',
// //     justifyContent: 'center',
// //   }}>
// //     <div style={{
// //       fontSize: '72px', // Adjust icon size
// //       marginBottom: '10px',
// //       cursor: 'pointer',
// //     }} onClick={onOpenApp}>
// //       📱
// //     </div>
// //     <div style={{
// //       fontSize: '18px',
// //       marginBottom: '20px',
// //     }}>
// //       Device Info App
// //     </div>
// //     {/* <button 
// //       onClick={onOpenApp} 
// //       style={{
// //         padding: '10px 20px',
// //         fontSize: '16px',
// //         borderRadius: '5px',
// //         border: 'none',
// //         backgroundColor: '#007BFF',
// //         color: '#fff',
// //         cursor: 'pointer'
// //       }}>
// //       Open Device Info
// //     </button> */}
// //   </div>
// // );

// // // DeviceInfo component to show device details
// // const DeviceInfo = ({ deviceDetails }) => (
// //   <div style={{
// //     padding: '20px',
// //     textAlign: 'left',
// //     backgroundColor: '#fff',
// //     borderRadius: '8px',
// //     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //     width: '100%',
// //     height: '100%',
// //   }}>
// //     <h2>Device Information</h2>
// //     <p><strong>IMEI Number:</strong> {deviceDetails.imeiNumber}</p>
// //     <p><strong>Device Name:</strong> {deviceDetails.deviceName}</p>
// //     <p><strong>Device Model:</strong> {deviceDetails.deviceModel}</p>
// //     <p><strong>Photos Count:</strong> {deviceDetails.photosCount}</p>
// //     <p><strong>Audio Files Count:</strong> {deviceDetails.audioFilesCount}</p>
// //     <p><strong>Videos Count:</strong> {deviceDetails.videosCount}</p>
// //     <p><strong>Installed Apps Count:</strong> {deviceDetails.installedAppsCount}</p>
// //     <p><strong>Android Version:</strong> {deviceDetails.androidVersion}</p>
// //     <p><strong>Location:</strong> {deviceDetails.location}</p>
// //   </div>
// // );

// // const App = () => {
// //   const [deviceDetails, setDeviceDetails] = useState(null);
// //   const [isAppOpen, setIsAppOpen] = useState(false);

// //   useEffect(() => {
// //     const fetchDeviceDetails = async () => {
// //       try {
// //         const response = await fetch('http://localhost:4040/api/device-details');
// //         const data = await response.json();
// //         setDeviceDetails(data);
// //       } catch (error) {
// //         console.error('Error fetching device details:', error);
// //       }
// //     };

// //     fetchDeviceDetails();
// //   }, []);

// //   if (!deviceDetails) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <div style={{
// //       display: 'flex',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       height: '100vh',
// //       backgroundColor: '#f5f5f5'
// //     }}>
// //       <div style={{
// //         width: '360px', // Set width for the emulator container
// //         height: '640px', // Set height for the emulator container
// //         position: 'relative',
// //         // backgroundColor: '#fff', // Ensure emulator has a white background
// //       }}>
// //         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
// //           {isAppOpen ? (
// //             <DeviceInfo deviceDetails={deviceDetails} />
// //           ) : (
// //             <HomeScreen onOpenApp={() => setIsAppOpen(true)} />
// //           )}
// //         </DeviceEmulator>
// //       </div>
// //     </div>
// //   );
// // };

// // export default App;

// // import React, { useState, useEffect } from 'react';
// // import DeviceEmulator from 'react-device-emulator';
// // import 'react-device-emulator/lib/styles/style.css';

// // // Custom Searchable Dropdown Component
// // const SearchableDropdown = ({ devices, selectedDeviceId, onSelectDevice }) => {
// //   const [searchTerm, setSearchTerm] = useState('');
// //   const [isOpen, setIsOpen] = useState(false);
  
// //   const filteredDevices = devices.filter(device =>
// //     device.deviceName.toLowerCase().includes(searchTerm.toLowerCase())
// //   );

// //   const handleSelect = (device) => {
// //     onSelectDevice(device);
// //     setIsOpen(false);
// //   };

// //   return (
// //     <div style={{ position: 'relative', width: '80%', maxWidth: '300px' }}>
// //       <input
// //         type="text"
// //         placeholder="Search devices..."
// //         value={searchTerm}
// //         onChange={(e) => setSearchTerm(e.target.value)}
// //         onClick={() => setIsOpen(!isOpen)}
// //         style={{
// //           padding: '10px',
// //           fontSize: '16px',
// //           width: '100%',
// //           borderRadius: '5px',
// //           border: '1px solid #ddd',
// //           cursor: 'pointer',
// //         }}
// //       />
// //       {isOpen && (
// //         <div style={{
// //           position: 'absolute',
// //           top: '100%',
// //           left: '0',
// //           width: '100%',
// //           backgroundColor: '#fff',
// //           border: '1px solid #ddd',
// //           borderRadius: '5px',
// //           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //           maxHeight: '200px',
// //           overflowY: 'hidden',
// //           zIndex: 1,
// //         }}>
// //           {filteredDevices.length === 0 ? (
// //             <div style={{ padding: '10px' }}>No devices found</div>
// //           ) : (
// //             filteredDevices.map(device => (
// //               <div
// //                 key={device.id}
// //                 onClick={() => handleSelect(device)}
// //                 style={{
// //                   padding: '10px',
// //                   cursor: 'pointer',
// //                   borderBottom: '1px solid #ddd',
// //                   backgroundColor: selectedDeviceId === device.id ? '#f0f0f0' : '#fff',
// //                 }}
// //               >
// //                 {device.deviceName}
// //               </div>
// //             ))
// //           )}
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // // HomeScreen component with searchable dropdown
// // const HomeScreen = ({ onOpenApp, devices, onSelectDevice }) => {
// //   const [selectedDeviceId, setSelectedDeviceId] = useState('');

// //   const handleSelectDevice = (device) => {
// //     setSelectedDeviceId(device.id);
// //     onSelectDevice(device);
// //   };

// //   return (
// //     <div style={{
// //       padding: '20px',
// //       textAlign: 'center',
// //       backgroundColor: '#fff',
// //       borderRadius: '8px',
// //       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //       // width: '100%',
// //       height: '100%',
// //       display: 'flex',
// //       flexDirection: 'column',
// //       alignItems: 'center',
// //       justifyContent: 'center',
// //       overflow:'auto'
// //     }}>
// //       <SearchableDropdown
// //         devices={devices}
// //         selectedDeviceId={selectedDeviceId}
// //         onSelectDevice={handleSelectDevice}
// //       />
// //       {selectedDeviceId && (
// //         <button 
// //           onClick={onOpenApp} 
// //           style={{
// //             padding: '10px 20px',
// //             fontSize: '16px',
// //             borderRadius: '5px',
// //             border: 'none',
// //             backgroundColor: '#007BFF',
// //             color: '#fff',
// //             cursor: 'pointer',
// //             marginTop: '20px',
// //           }}>
// //           Show Device Info
// //         </button>
// //       )}
// //     </div>
// //   );
// // };

// // // DeviceInfo component to show device details
// // const DeviceInfo = ({ deviceDetails, onBack }) => (
// //   <div style={{
// //     padding: '20px',
// //     textAlign: 'left',
// //     backgroundColor: '#fff',
// //     borderRadius: '8px',
// //     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //     width: '100%',
// //     height: 'calc(100% - 50px)', // Reserve space for the back button
// //     display: 'flex',
// //     flexDirection: 'column',
// //     justifyContent: 'space-between',
// //     overflowY: 'hidden', // Ensure vertical scrolling is enabled only if needed
// //   }}>
// //     <div>
// //       <h2>Device Information</h2>
// //       <p><strong>IMEI Number:</strong> {deviceDetails.imeiNumber}</p>
// //       <p><strong>Device Name:</strong> {deviceDetails.deviceName}</p>
// //       <p><strong>Device Model:</strong> {deviceDetails.deviceModel}</p>
// //       <p><strong>Photos Count:</strong> {deviceDetails.photosCount}</p>
// //       <p><strong>Audio Files Count:</strong> {deviceDetails.audioFilesCount}</p>
// //       <p><strong>Videos Count:</strong> {deviceDetails.videosCount}</p>
// //       <p><strong>Installed Apps Count:</strong> {deviceDetails.installedAppsCount}</p>
// //       <p><strong>Android Version:</strong> {deviceDetails.androidVersion}</p>
// //       <p><strong>Location:</strong> {deviceDetails.location}</p>
// //     </div>
// //     <button
// //       onClick={onBack}
// //       style={{
// //         padding: '10px',
// //         fontSize: '16px',
// //         borderRadius: '5px',
// //         border: 'none',
// //         backgroundColor: '#007BFF',
// //         color: '#fff',
// //         cursor: 'pointer',
// //         position: 'fixed', // Position fixed at the bottom of the screen
// //         bottom: '0',
// //         left: '50%',
// //         transform: 'translateX(-50%)',
// //         width: '100%',
// //       }}
// //     >
// //       Back
// //     </button>
// //   </div>
// // );

// // const App = () => {
// //   const [devices, setDevices] = useState([]);
// //   const [deviceDetails, setDeviceDetails] = useState(null);
// //   const [isAppOpen, setIsAppOpen] = useState(false);

// //   useEffect(() => {
// //     const fetchDevices = async () => {
// //       try {
// //         const response = await fetch('http://localhost:4040/api/device-details');
// //         const data = await response.json();
// //         setDevices(data);
// //       } catch (error) {
// //         console.error('Error fetching devices:', error);
// //       }
// //     };

// //     fetchDevices();
// //   }, []);

// //   const handleSelectDevice = (device) => {
// //     setDeviceDetails(device);
// //   };

// //   const handleBack = () => {
// //     setIsAppOpen(false);
// //   };

// //   return (
// //     <div style={{
// //       display: 'flex',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       height: '100vh',
// //       backgroundColor: isAppOpen ? '#fff' : '#f5f5f5',
// //       overflow: 'hidden',
// //     }}>
// //       <div style={{
// //         width: '360px',
// //         height: '640px',
// //         position: 'relative',
   
// //       }}>
// //         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
// //           {isAppOpen ? (
// //             <DeviceInfo deviceDetails={deviceDetails} onBack={handleBack} />
// //           ) : (
// //             <HomeScreen 
// //               onOpenApp={() => setIsAppOpen(true)} 
// //               devices={devices} 
// //               onSelectDevice={handleSelectDevice} 
// //             />
// //           )}
// //         </DeviceEmulator>
// //       </div>
// //     </div>
// //   );
// // };

// // export default App;




// // import React, { useState, useEffect } from 'react';
// // import DeviceEmulator from 'react-device-emulator';
// // import 'react-device-emulator/lib/styles/style.css';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// // // Custom Searchable Dropdown Component
// // const SearchableDropdown = ({ devices, selectedDeviceId, onSelectDevice }) => {
// //   const [searchTerm, setSearchTerm] = useState('');
// //   const [isOpen, setIsOpen] = useState(false);
  
// //   const filteredDevices = devices.filter(device =>
// //     device.deviceName.toLowerCase().includes(searchTerm.toLowerCase())
// //   );

// //   const handleSelect = (device) => {
// //     onSelectDevice(device);
// //     setIsOpen(false);
// //   };

// //   return (
// //     <div style={{ position: 'relative', width: '80%', maxWidth: '300px', zIndex: 2 }}>
// //       <input
// //         type="text"
// //         placeholder="Search devices..."
// //         value={searchTerm}
// //         onChange={(e) => setSearchTerm(e.target.value)}
// //         onClick={() => setIsOpen(!isOpen)}
// //         style={{
// //           padding: '10px',
// //           fontSize: '16px',
// //           width: '100%',
// //           borderRadius: '12px',
// //           border: '1px solid #ddd',
// //           cursor: 'pointer',
// //         }}
// //       />
// //       {isOpen && (
// //         <div style={{
// //           position: 'absolute',
// //           // top: '100%',
// //           left: '0',
// //           // width: '100%',
// //           width:'190px',
// //           backgroundColor: '#fff',
// //           border: '1px solid #ddd',
// //           borderRadius: '5px',
// //           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //           maxHeight: '150px',
// //           overflowY: 'auto',
// //           marginTop:'5px',
// //           zIndex: 10,
// //         }}>
// //           {filteredDevices.length === 0 ? (
// //             <div style={{ padding: '10px' }}>No devices found</div>
// //           ) : (
// //             filteredDevices.map(device => (
// //               <div
// //                 key={device.id}
// //                 onClick={() => handleSelect(device)}
// //                 style={{
// //                   padding: '10px',
// //                   cursor: 'pointer',
// //                   borderBottom: '1px solid #ddd',
// //                   backgroundColor: selectedDeviceId === device.id ? '#f0f0f0' : '#fff',
// //                 }}
// //               >
// //                 {device.deviceName}
// //               </div>
// //             ))
// //           )}
// //         </div>
// //       )}
// //     </div>
// //   );
// // };


// // const HomeScreen = ({ onOpenApp, devices, onSelectDevice }) => {
// //   const [selectedDeviceId, setSelectedDeviceId] = useState('');
// //   const [selectedDeviceName, setSelectedDeviceName] = useState('');

// //   const handleSelectDevice = (device) => {
// //     setSelectedDeviceId(device.id);
// //     setSelectedDeviceName(device.deviceName);
// //     onSelectDevice(device);
// //   };

// //   return (
// //     <div style={{
// //       padding: '20px',
// //       textAlign: 'center',
// //       backgroundColor: '#fff',
// //       borderRadius: '8px',
// //       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //       height: '400px',
// //       display: 'flex',
// //       flexDirection: 'column',
// //       alignItems: 'center',
// //       justifyContent: 'center',
// //       overflow: 'hidden',
// //     }}>
// //       <SearchableDropdown
// //         devices={devices}
// //         selectedDeviceId={selectedDeviceId}
// //         onSelectDevice={handleSelectDevice}
// //       />
// //       {selectedDeviceName && (
// //         <div style={{ margin: '20px 0' }}>
// //           <h3>Selected Device:</h3>
// //           <p>{selectedDeviceName}</p>
// //         </div>
// //       )}
// //       {selectedDeviceId && (
// //         <button 
// //           onClick={onOpenApp} 
// //           style={{
// //             padding: '10px 20px',
// //             fontSize: '16px',
// //             borderRadius: '5px',
// //             border: 'none',
// //             backgroundColor: '#007BFF',
// //             color: '#fff',
// //             cursor: 'pointer',
// //           }}>
// //           Show Device Info
// //         </button>
// //       )}
// //     </div>
// //   );
// // };
// // // DeviceInfo component to show device details
// // const DeviceInfo = ({ deviceDetails, onBack }) => (
// //   <div style={{
// //     padding: '20px',
// //     textAlign: 'left',
// //     backgroundColor: '#fff',
// //     borderRadius: '8px',
// //     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //     // width: '100%',
// //     height: '400px', 
// //     display: 'flex',
// //     flexDirection: 'column',
// //     justifyContent: 'space-between',
// //     overflowY: 'auto', 
// //   }}>
// //     <div>
// //       <h2>Device Information</h2>
// //       <p><strong>IMEI Number:</strong> {deviceDetails.imeiNumber}</p>
// //       <p><strong>Device Name:</strong> {deviceDetails.deviceName}</p>
// //       <p><strong>Device Model:</strong> {deviceDetails.deviceModel}</p>
// //       <p><strong>Photos Count:</strong> {deviceDetails.photosCount}</p>
// //       <p><strong>Audio Files Count:</strong> {deviceDetails.audioFilesCount}</p>
// //       <p><strong>Videos Count:</strong> {deviceDetails.videosCount}</p>
// //       <p><strong>Installed Apps Count:</strong> {deviceDetails.installedAppsCount}</p>
// //       <p><strong>Android Version:</strong> {deviceDetails.androidVersion}</p>
// //       <p><strong>Location:</strong> {deviceDetails.location}</p>
// //     </div>
// //     {/* <button
// //       onClick={onBack}
// //       style={{
// //         padding: '10px',
// //         fontSize: '16px',
// //         borderRadius: '5px',
// //         border: 'none',
// //         backgroundColor: '#007BFF',
// //         color: '#fff',
// //         cursor: 'pointer',
// //         position: 'fixed', // Position fixed at the bottom of the screen
// //         bottom: '0',
// //         left: '50%',
// //         transform: 'translateX(-50%)',
// //         width: '50%',
// //         marginTop:'50px'
// //       }}
// //     >
// //       Back
// //     </button> */}
// //      <button
// //       onClick={onBack}
// //       style={{
// //         padding: '10px',
// //         fontSize: '16px',
// //         borderRadius: '50%',
// //         border: 'none',
// //         backgroundColor: '#007BFF',
// //         color: '#fff',
// //         cursor: 'pointer',
// //         // position: 'fixed',
// //         bottom: '10px',
    
// //         width: '40px',
// //         height: '40px',
// //         display: 'flex',
// //         alignItems: 'center',
// //         justifyContent: 'center',
// //         boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
// //       }}
// //     >
// //       <FontAwesomeIcon icon={faArrowLeft} />
// //     </button>
// //   </div>
// // );

// // const App = () => {
// //   const [devices, setDevices] = useState([]);
// //   const [deviceDetails, setDeviceDetails] = useState(null);
// //   const [isAppOpen, setIsAppOpen] = useState(false);

// //   useEffect(() => {
// //     const fetchDevices = async () => {
// //       try {
// //         const response = await fetch('http://localhost:4040/api/device-details');
// //         const data = await response.json();
// //         setDevices(data);
// //       } catch (error) {
// //         console.error('Error fetching devices:', error);
// //       }
// //     };

// //     fetchDevices();
// //   }, []);

// //   const handleSelectDevice = (device) => {
// //     setDeviceDetails(device);
// //   };

// //   const handleBack = () => {
// //     setIsAppOpen(false);
// //   };

// //   return (
// //     <div style={{
// //       display: 'flex',
// //       justifyContent: 'center',
// //       alignItems: 'center',
// //       height: '100vh',
// //       backgroundColor: isAppOpen ? '#fff' : '#f5f5f5',
// //       overflow: 'hidden',
// //     }}>
// //       <div style={{
// //         width: '310px',
// //         height: '620px',
// //         position: 'relative',
// //       }}>
// //         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
// //           {isAppOpen ? (
// //             <DeviceInfo deviceDetails={deviceDetails} onBack={handleBack} />
// //           ) : (
// //             <HomeScreen 
// //               onOpenApp={() => setIsAppOpen(true)} 
// //               devices={devices} 
// //               onSelectDevice={handleSelectDevice} 
// //             />
// //           )}
// //         </DeviceEmulator>
// //       </div>
// //     </div>
// //   );
// // };

// // export default App;



// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; 
// import Device from './components/pages/Dashboard';
// import Layout from './components/pages/Layout';
// import Login from './components/auth/Login'; 
// import { AuthProvider } from './components/context/authcontext';

// const App = () => {
//   return (
//     <Router>
//       <AuthProvider>

     
    
//         <Routes>
//           {/* Base URL redirects to Login */}
//           {/* <Route path="/" element={<Navigate to="/login" />} /> */}
          
//           <Route path="/" element={<Login />} />
//           <Route path="/d" element={<Layout />} />
//         </Routes>
//         </AuthProvider>
    
//     </Router>
//   );
// };

// export default App;



import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; 
import Device from './components/pages/Dashboard';
import Layout from './components/pages/Layout';
import Login from './components/auth/Login'; 
import { AuthProvider } from './components/context/authcontext';
import ProtectedRoute from './components/auth/protectedRoute';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          {/* Protected route with role-based access */}
          <Route path="/" element={
            <ProtectedRoute roles={['admin', 'user']}>
              <Layout />
            </ProtectedRoute>
          } />

          {/* Unauthorized access route */}
          <Route path="/unauthorized" element={<div>Access Denied</div>} />
          
          {/* Fallback route for any unhandled paths */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
