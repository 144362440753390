// // import React from 'react';
// // import { Canvas } from '@react-three/fiber';
// // import { OrbitControls, useGLTF } from '@react-three/drei';

// // // Model component to load the GLTF model
// // const Model = () => {
// //   const { scene } = useGLTF('/phone.glb'); 
// //   return <primitive object={scene} />;
// // };

// // // Main component to render the 3D scene
// // const ThreeDModel = () => {
// //   return (
// //     <div style={{ width: '100vw', height: '100vh' }}>
// //       <Canvas
// //         style={{ backgroundColor: '#f0f0f0' }} // Light background color
// //         gl={{ antialias: true }}
// //         camera={{ position: [0, 0, 5] }}
// //         className='mt-5 , py-5 ' // Adjust camera position
// //       >
// //         {/* OrbitControls with autoRotate enabled */}
// //         <OrbitControls 
// //           autoRotate // Enables automatic rotation
// //           autoRotateSpeed={2} // Adjusts the speed of rotation (default is 2)
// //           enableZoom={true} // Allows zooming in and out
// //           enablePan={false} // Disables panning to prevent the model from moving off-center
// //         />
// //         <ambientLight intensity={0.8} /> {/* Increased ambient light */}
// //         <pointLight position={[10, 10, 10]} intensity={1.2} /> {/* Brighter point light */}
// //         <pointLight position={[-10, -10, -10]} intensity={0.5} /> {/* Extra light for balance */}
// //         <Model />
// //       </Canvas>
// //     </div>
// //   );
// // };

// // export default ThreeDModel;






// import React, { useState, useEffect } from 'react';
// import { Canvas } from '@react-three/fiber';
// import { OrbitControls, useGLTF } from '@react-three/drei';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import '../../App.css';

// // Model component to load the GLTF model
// const Model = () => {
//   const { scene } = useGLTF('/phone.glb'); 
//   return <primitive object={scene} scale={[2.5, 2.5, 2.5]} />; // Increase size by scaling
// };

// // Main component to render the 3D scene
// const ThreeDModel = () => {
//   const [showMap, setShowMap] = useState(false);

//   // Add a delay for the animation to simulate the data appearing
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowMap(true);
//     }, 2000); // Show map after 2 seconds
//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div style={{ width: '100vw', height: '100vh', position: 'relative', backgroundColor: '#f5f5f5' }}>
//       {/* 3D Canvas */}
//       <Canvas
//         style={{ backgroundColor: '#ffffff' }} // Light background
//         gl={{ antialias: true }}
//         camera={{ position: [0, 0, 8] }} // Adjust the camera position slightly to account for bigger model
//         className='responsive-canvas' // Add responsive class
//       >
//         <OrbitControls
//           autoRotate
//           autoRotateSpeed={2}
//           enableZoom={true}
//           enableRotate={true} // Enable full rotation
//           enablePan={false}
//         />
//         <ambientLight intensity={0.8} />
//         <pointLight position={[10, 10, 10]} intensity={1.2} />
//         <pointLight position={[-10, -10, -10]} intensity={0.5} />
//         <Model />
//       </Canvas>

//       {/* Information Overlay with Animated Entry */}
//       <div className="info-container">
//         <div className="info-box animate-right">Device Model: Galaxy S21 Ultra</div>
//         <div className="info-box animate-right">IMEI: 356938035643809</div>
//         <div className="info-box animate-right">Images Count: 1253</div>
//         <div className="info-box animate-right">Android Version: 11.0</div>
//         <div className="info-box animate-right">Photos Count: <span>4123</span></div>
//         <div className="info-box animate-right">Videos Count: <span>6789</span></div>
//         <div className="info-box animate-right">Audio Files Count: <span>6789</span></div>
//         <div className="info-box animate-right">Live Location: 37.7749° N, 122.4194° W</div>
//       </div>

//       {/* Map Overlay - Shows after 2 seconds */}
//       {showMap && (
//         <div className="map-container animate-bottom-right">
//           <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
//             <GoogleMap
//               mapContainerStyle={{ width: '100%', height: '100%' }}
//               center={{ lat: 37.7749, lng: -122.4194 }} // Coordinates for San Francisco
//               zoom={12}
//             >
//               <Marker position={{ lat: 37.7749, lng: -122.4194 }} />
//             </GoogleMap>
//           </LoadScript>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ThreeDModel;




// import React, { useState, useEffect } from 'react';
// import DeviceEmulator from 'react-device-emulator';
// import 'react-device-emulator/lib/styles/style.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// // Custom Searchable Dropdown Component
// const SearchableDropdown = ({ devices, selectedDeviceId, onSelectDevice }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isOpen, setIsOpen] = useState(false);
  
//   const filteredDevices = devices.filter(device =>
//     device.deviceName?.toLowerCase().includes(searchTerm.toLowerCase())
//   );
  
//   const handleSelect = (device) => {
//     onSelectDevice(device);
//     setIsOpen(false);
//   };

//   return (
//     <div style={{ position: 'relative', width: '80%', maxWidth: '300px', zIndex: 2 }}>
//       <input
//         type="text"
//         placeholder="Search devices..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         onClick={() => setIsOpen(!isOpen)}
//         style={{
//           padding: '10px',
//           fontSize: '16px',
//           width: '100%',
//           borderRadius: '12px',
//           border: '1px solid #ddd',
//           cursor: 'pointer',
//         }}
//       />
//       {isOpen && (
//         <div style={{
//           position: 'absolute',
//           // top: '100%',
//           left: '0',
//           // width: '100%',
//           width:'190px',
//           backgroundColor: '#fff',
//           border: '1px solid #ddd',
//           borderRadius: '5px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//           maxHeight: '150px',
//           overflowY: 'auto',
//           marginTop:'5px',
//           zIndex: 10,
//         }}>
//           {filteredDevices.length === 0 ? (
//             <div style={{ padding: '10px' }}>No devices found</div>
//           ) : (
//             filteredDevices.map(device => (
//               <div
//                 key={device.id}
//                 onClick={() => handleSelect(device)}
//                 style={{
//                   padding: '10px',
//                   cursor: 'pointer',
//                   borderBottom: '1px solid #ddd',
//                   backgroundColor: selectedDeviceId === device.id ? '#f0f0f0' : '#fff',
//                 }}
//               >
//                 {device.deviceName}
//               </div>
//             ))
//           )}
//         </div>
//       )}
//     </div>
//   );
// };


// const HomeScreen = ({ onOpenApp, devices, onSelectDevice }) => {
//   const [selectedDeviceId, setSelectedDeviceId] = useState('');
//   const [selectedDeviceName, setSelectedDeviceName] = useState('');

//   const handleSelectDevice = (device) => {
//     setSelectedDeviceId(device.id);
//     setSelectedDeviceName(device.deviceName);
//     onSelectDevice(device);
//   };

//   return (
//     <div style={{
//       padding: '20px',
//       textAlign: 'center',
//       backgroundColor: '#fff',
//       borderRadius: '8px',
//       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       height: '400px',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       justifyContent: 'center',
//       overflow: 'hidden',
//     }}>
//       <SearchableDropdown
//         devices={devices}
//         selectedDeviceId={selectedDeviceId}
//         onSelectDevice={handleSelectDevice}
//       />
//       {selectedDeviceName && (
//         <div style={{ margin: '20px 0' }}>
//           <h3>Selected Device:</h3>
//           <p>{selectedDeviceName}</p>
//         </div>
//       )}
//       {selectedDeviceId && (
//         <button 
//           onClick={onOpenApp} 
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             borderRadius: '5px',
//             border: 'none',
//             backgroundColor: '#007BFF',
//             color: '#fff',
//             cursor: 'pointer',
//           }}>
//           Show Device Info
//         </button>
//       )}
//     </div>
//   );
// };
// // DeviceInfo component to show device details
// const DeviceInfo = ({ deviceDetails, onBack }) => (
//   <div style={{
//     padding: '20px',
//     textAlign: 'left',
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//     // width: '100%',
//     height: '400px', 
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     overflowY: 'auto', 
//   }}>
//     {/* <div>
//       <h2>Device Information</h2>
//       <p><strong>IMEI Number:</strong> {deviceDetails.imeiNumber}</p>
//       <p><strong>Device Name:</strong> {deviceDetails.deviceName}</p>
//       <p><strong>Device Model:</strong> {deviceDetails.deviceModel}</p>
//       <p><strong>Photos Count:</strong> {deviceDetails.photosCount}</p>
//       <p><strong>Audio Files Count:</strong> {deviceDetails.audioFilesCount}</p>
//       <p><strong>Videos Count:</strong> {deviceDetails.videosCount}</p>
//       <p><strong>Installed Apps Count:</strong> {deviceDetails.installedAppsCount}</p>
//       <p><strong>Android Version:</strong> {deviceDetails.androidVersion}</p>
//       <p><strong>Location:</strong> {deviceDetails.location}</p>
//     </div> */}
//     <div>
//       <h2>Device Information</h2>
//       {/* <p><strong>ID:</strong> {deviceDetails.id}</p> */}
//       <p><strong>Device Name:</strong> {deviceDetails.DeviceName}</p>
//       <p><strong>Device Model:</strong> {deviceDetails.DeviceModel}</p>
//       <p><strong>Device Model:</strong> {deviceDetails.AndroidVersion}</p>
//       <p><strong>Photos Count:</strong> {deviceDetails.PhotosCount}</p>
//       <p><strong>Audio Files Count:</strong> {deviceDetails.AudioFilesCount}</p>
//       <p><strong>Videos Count:</strong> {deviceDetails.VideosCount}</p>
//       <p><strong>Installed Apps Count:</strong> {deviceDetails.InstalledAppsCount}</p>
//       <p><strong>Location:</strong> {deviceDetails.location}</p>
//     </div>
//     {/* <button
//       onClick={onBack}
//       style={{
//         padding: '10px',
//         fontSize: '16px',
//         borderRadius: '5px',
//         border: 'none',
//         backgroundColor: '#007BFF',
//         color: '#fff',
//         cursor: 'pointer',
//         position: 'fixed', // Position fixed at the bottom of the screen
//         bottom: '0',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         width: '50%',
//         marginTop:'50px'
//       }}
//     >
//       Back
//     </button> */}
//      <button
//       onClick={onBack}
//       style={{
//         padding: '10px',
//         fontSize: '16px',
//         borderRadius: '50%',
//         border: 'none',
//         backgroundColor: '#007BFF',
//         color: '#fff',
//         cursor: 'pointer',
//         // position: 'fixed',
//         bottom: '10px',
    
//         width: '40px',
//         height: '40px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       }}
//     >
//       <FontAwesomeIcon icon={faArrowLeft} />
//     </button>
//   </div>
// );

// const Device = () => {
//   const [devices, setDevices] = useState([]);
//   const [deviceDetails, setDeviceDetails] = useState(null);
//   const [isAppOpen, setIsAppOpen] = useState(false);

//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         // const response = await fetch('https://backdtracker.supportplus.app/api/device-details');
//         const response = await fetch('http://localhost:4040/api/device-details');
//         const data = await response.json();
//         setDevices(data);
//       } catch (error) {
//         console.error('Error fetching devices:', error);
//       }
//     };

//     fetchDevices();
//   }, []);

//   const handleSelectDevice = (device) => {
//     setDeviceDetails(device);
//   };

//   const handleBack = () => {
//     setIsAppOpen(false);
//   };

//   return (
//     <div style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100vh',
//       // backgroundColor: isAppOpen ? '#fff' : '#f5f5f5',
//       overflow: 'hidden',
//     }}>
//       <div style={{
//         width: '310px',
//         height: '750px',
//         position: 'relative',
//       }}>
//         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
//           {isAppOpen ? (
//             <DeviceInfo deviceDetails={deviceDetails} onBack={handleBack} />
//           ) : (
//             <HomeScreen 
//               onOpenApp={() => setIsAppOpen(true)} 
//               devices={devices} 
//               onSelectDevice={handleSelectDevice} 
//             />
//           )}
//         </DeviceEmulator>
//       </div>
//     </div>
//   );
// };

// export default Device;




// import React, { useState, useEffect } from 'react';
// import DeviceEmulator from 'react-device-emulator';
// import 'react-device-emulator/lib/styles/style.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// // Custom Searchable Dropdown Component
// const SearchableDropdown = ({ devices, selectedDeviceId, onSelectDevice }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [isOpen, setIsOpen] = useState(false);
  
//   const filteredDevices = devices.filter(device =>
//     device.DeviceName?.toLowerCase().includes(searchTerm.toLowerCase())
//   );
  
//   const handleSelect = (device) => {
//     onSelectDevice(device);
//     setIsOpen(false);
//   };

//   return (
//     <div style={{ position: 'relative', width: '80%', maxWidth: '300px', zIndex: 2 }}>
//       <input
//         type="text"
//         placeholder="Search devices..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         onClick={() => setIsOpen(!isOpen)}
//         style={{
//           padding: '10px',
//           fontSize: '16px',
//           width: '100%',
//           borderRadius: '12px',
//           border: '1px solid #ddd',
//           cursor: 'pointer',
//         }}
//       />
//       {isOpen && (
//         <div style={{
//           position: 'absolute',
//           left: '0',
//           width: '190px',
//           backgroundColor: '#fff',
//           border: '1px solid #ddd',
//           borderRadius: '5px',
//           boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//           maxHeight: '150px',
//           overflowY: 'auto',
//           marginTop: '5px',
//           zIndex: 10,
//         }}>
//           {filteredDevices.length === 0 ? (
//             <div style={{ padding: '10px' }}>No devices found</div>
//           ) : (
//             filteredDevices.map(device => (
//               <div
//                 key={device['Device Id']}
//                 onClick={() => handleSelect(device)}
//                 style={{
//                   padding: '10px',
//                   cursor: 'pointer',
//                   borderBottom: '1px solid #ddd',
//                   backgroundColor: selectedDeviceId === device['Device Id'] ? '#f0f0f0' : '#fff',
//                 }}
//               >
//                 {device.DeviceName}
//               </div>
//             ))
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// const HomeScreen = ({ onOpenApp, devices, onSelectDevice }) => {
//   const [selectedDeviceId, setSelectedDeviceId] = useState('');
//   const [selectedDeviceName, setSelectedDeviceName] = useState('');

  
//   const handleSelectDevice = (device) => {
//     setSelectedDeviceId(device['Device Id']);
//     setSelectedDeviceName(device.DeviceName);
//     onSelectDevice(device);
//   };

//   return (
//     <div style={{
//       padding: '20px',
//       textAlign: 'center',
//       backgroundColor: '#fff',
//       borderRadius: '8px',
//       boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       height: '400px',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       justifyContent: 'center',
//       overflow: 'hidden',
//     }}>
//       <SearchableDropdown
//         devices={devices}
//         selectedDeviceId={selectedDeviceId}
//         onSelectDevice={handleSelectDevice}
//       />
//       {selectedDeviceName && (
//         <div style={{ margin: '20px 0' }}>
//           <h3>Selected Device:</h3>
//           <p>{selectedDeviceName}</p>
//         </div>
//       )}
//       {selectedDeviceId && (
//         <button 
//           onClick={onOpenApp} 
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             borderRadius: '5px',
//             border: 'none',
//             backgroundColor: '#007BFF',
//             color: '#fff',
//             cursor: 'pointer',
//           }}>
//           Show Device Info
//         </button>
//       )}
//     </div>
//   );
// };

// // DeviceInfo component to show device details
// const DeviceInfo = ({ deviceDetails, onBack }) => {

//   const [latitude, longitude] = deviceDetails['Globally location format']
//   .replace('Latitude:', '')
//   .replace('Longitude', '')
//   .split(',');

// // Function to handle the click and redirect to Google Maps
// const handleLocationClick = () => {
//   const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
//   window.open(googleMapsUrl, '_blank'); // Open in a new tab
// };


//   <div style={{
//     padding: '20px',
//     textAlign: 'left',
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//     height: '400px', 
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     overflowY: 'auto', 
//   }}>
//     {/* <div>
//       <h2>Device Information</h2>
//       <p><strong>Device Name:</strong> {deviceDetails.DeviceName}</p>
//       <p><strong>Device Model:</strong> {deviceDetails['Device Model']}</p>
//       <p><strong>Android Version:</strong> {deviceDetails['Android Version']}</p>
//       <p><strong>Photos Count:</strong> {deviceDetails['Photos Count']}</p>
//       <p><strong>Audio Files Count:</strong> {deviceDetails['Audio Files Count']}</p>
//       <p><strong>Videos Count:</strong> {deviceDetails['Videos Count']}</p>
//       <p><strong>Installed Apps Count:</strong> {deviceDetails['InstalledAppsCount']}</p>
//       <p><strong>Location:</strong> {deviceDetails.location}</p>
//     </div> */}
//     <div>
//       <h2>Device Information</h2>
//       <p><strong>Device Name:</strong> {deviceDetails['Device Name']}</p>
//       <p><strong>Device Model:</strong> {deviceDetails['Device Model']}</p>
//       <p><strong>Android Version:</strong> {deviceDetails['Android Version']}</p>
//       <p><strong>Photos Count:</strong> {deviceDetails['Photos Count']}</p>
//       <p><strong>Audio Files Count:</strong> {deviceDetails['Audio Files Count']}</p>
//       <p><strong>Videos Count:</strong> {deviceDetails['Videos Count']}</p>
//       <p><strong>Installed Apps Count:</strong> {deviceDetails['Installed Apps Count']}</p>
//       <p><strong>Location:</strong> {deviceDetails['Live Location']}</p>
//       <p>
//         <strong>Global Location:</strong>{' '}
//         <a href="#" onClick={handleLocationClick}>
//           {deviceDetails['Globally location format']}
//         </a>
//       </p>
//     </div>
//     <button
//       onClick={onBack}
//       style={{
//         padding: '10px',
//         fontSize: '16px',
//         borderRadius: '50%',
//         border: 'none',
//         backgroundColor: '#007BFF',
//         color: '#fff',
//         cursor: 'pointer',
//         bottom: '10px',
//         width: '40px',
//         height: '40px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
//       }}
//     >
//       <FontAwesomeIcon icon={faArrowLeft} />
//     </button>
//   </div>
// };

// const Device = () => {
//   const [devices, setDevices] = useState([]);
//   const [deviceDetails, setDeviceDetails] = useState(null);
//   const [isAppOpen, setIsAppOpen] = useState(false);

//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         // const response = await fetch('https://backdtracker.supportplus.app/api/device-details');
//         const response = await fetch('http://localhost:4040/api/device-details');
//         const data = await response.json();
//         console.log("data",data )
//         setDevices(data);
//       } catch (error) {
//         console.error('Error fetching devices:', error);
//       }
//     };

//     fetchDevices();
//   }, []);

//   const handleSelectDevice = (device) => {
//     setDeviceDetails(device);
//   };

//   const handleBack = () => {
//     setIsAppOpen(false);
//   };

//   return (
//     <div style={{
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100vh',
//       overflow: 'hidden',
//     }}>
//       <div style={{
//         width: '310px',
//         height: '750px',
//         position: 'relative',
//       }}>
//         <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
//           {isAppOpen ? (
//             <DeviceInfo deviceDetails={deviceDetails} onBack={handleBack} />
//           ) : (
//             <HomeScreen 
//               onOpenApp={() => setIsAppOpen(true)} 
//               devices={devices} 
//               onSelectDevice={handleSelectDevice} 
//             />
//           )}
//         </DeviceEmulator>
//       </div>
//     </div>
//   );
// };

// export default Device;


import React, { useState, useEffect } from 'react';
import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Custom Searchable Dropdown Component
const SearchableDropdown = ({ devices, selectedDeviceId, onSelectDevice }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const filteredDevices = devices.filter(device =>
    device['Device Name']?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (device) => {
    onSelectDevice(device);
    setIsOpen(false);
  };

  return (
    <div style={{ position: 'relative', width: '100%', maxWidth: '350px', zIndex: 2 }}>
      <input
        type="text"
        placeholder="Search devices..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: '10px',
          fontSize: '16px',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #ddd',
          cursor: 'pointer',
          boxSizing: 'border-box',
        }}
      />
      {isOpen && (
        <div style={{
          position: 'absolute',
          left: '0',
          width: '100%',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          maxHeight: '200px',
          overflowY: 'auto',
          marginTop: '5px',
          zIndex: 10,
        }}>
          {filteredDevices.length === 0 ? (
            <div style={{ padding: '10px' }}>No devices found</div>
          ) : (
            filteredDevices.map(device => (
              <div
                key={device['Device ID']}
                onClick={() => handleSelect(device)}
                style={{
                  padding: '10px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #ddd',
                  backgroundColor: selectedDeviceId === device['Device ID'] ? '#f0f0f0' : '#fff',
                }}
              >
                {device['Device Name']}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const HomeScreen = ({ onOpenApp, devices, onSelectDevice }) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [selectedDeviceName, setSelectedDeviceName] = useState('');

  const handleSelectDevice = (device) => {
    setSelectedDeviceId(device['Device ID']);
    setSelectedDeviceName(device['Device Name']);
    onSelectDevice(device);
  };

  return (
    <div style={{
      padding: '20px',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      height: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }}>
      <SearchableDropdown
        devices={devices}
        selectedDeviceId={selectedDeviceId}
        onSelectDevice={handleSelectDevice}
      />
      {selectedDeviceName && (
        <div style={{ margin: '20px 0' }}>
          <h3>Selected Device:</h3>
          <p>{selectedDeviceName}</p>
        </div>
      )}
      {selectedDeviceId && (
        <button 
          onClick={onOpenApp} 
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '5px',
            border: 'none',
            backgroundColor: '#007BFF',
            color: '#fff',
            cursor: 'pointer',
          }}>
          Show Device Info
        </button>
      )}
    </div>
  );
};

// DeviceInfo component to show device details
const DeviceInfo = ({ deviceDetails, onBack }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (deviceDetails && deviceDetails['Globally location  format']) {
      const [lat, long] = deviceDetails['Globally location  format']
        .replace('Latitude:', '')
        .replace('Longitude:', '')
        .split(',');

      setLatitude(lat.trim());
      setLongitude(long.trim());
    }
  }, [deviceDetails]);

  const handleLocationClick = () => {
    if (latitude && longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsUrl, '_blank');
    }
  };

  return (
    <div style={{
      padding: '20px',
      textAlign: 'left',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      height: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowY: 'auto',
    }}>
      <div>
        <h2>Device Information</h2>
        <p><strong>Device Name:</strong> {deviceDetails['Device Name']}</p>
        <p><strong>Device Model:</strong> {deviceDetails['Device Model']}</p>
        <p><strong>Android Version:</strong> {deviceDetails['Android Version']}</p>
        <p><strong>Photos Count:</strong> {deviceDetails['Photos Count']}</p>
        <p><strong>Audio Files Count:</strong> {deviceDetails['Audio Files Count']}</p>
        <p><strong>Videos Count:</strong> {deviceDetails['Videos Count']}</p>
        <p><strong>Installed Apps Count:</strong> {deviceDetails['Installed Apps Count']}</p>
        <p><strong>Location:</strong> {deviceDetails['Live Location']}</p>
        <p>
          <strong>Global Location:</strong>{' '}
          {latitude && longitude ? (
            <a href="#" onClick={handleLocationClick}
              style={{ 
                textDecoration: 'none', 
                color: '#007BFF', 
              }}
            >
              {deviceDetails['Globally location  format']}
            </a>
          ) : (
            'Location not available'
          )}
        </p>
      </div>
      <button
        onClick={onBack}
        style={{
          padding: '10px',
          fontSize: '16px',
          borderRadius: '50%',
          border: 'none',
          backgroundColor: '#007BFF',
          color: '#fff',
          cursor: 'pointer',
          width: '40px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
    </div>
  );
};

const Device = () => {
  const [devices, setDevices] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [isAppOpen, setIsAppOpen] = useState(false);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await fetch('https://backdtracker.supportplus.app/api/device-details');
        // const response = await fetch('http://localhost:4040/api/device-details');
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const handleSelectDevice = (device) => {
    setDeviceDetails(device);
    setIsAppOpen(true);
  };

  const handleBack = () => {
    setIsAppOpen(false);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      overflow: 'hidden',
      // boxSizing: 'border-box',
    }}>
      <div style={{
        width: '310px',
        // Responsive max width
        // maxWidth: '375px', 
        height: '720px',
      // Responsive max height for mobile devices
      // maxHeight: '667px',
        position: 'relative',
      }}>
        <DeviceEmulator type="mobile" withRotator={false} withDeviceSwitch={false} style={{ width: '100%', height: '100%' }}>
          {isAppOpen ? (
            <DeviceInfo deviceDetails={deviceDetails} onBack={handleBack} />
          ) : (
            <HomeScreen 
              onOpenApp={() => setIsAppOpen(true)} 
              devices={devices} 
              onSelectDevice={handleSelectDevice} 
            />
          )}
        </DeviceEmulator>
      </div>
    </div>
  );
};

export default Device;
