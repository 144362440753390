import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authcontext';

const ProtectedRoute = ({ roles = [], children }) => {
  const { authData } = useAuth();

  // Check if user is logged in
  if (!authData || !authData.user) {
    return <Navigate to="/login" replace />;
  }

  // If roles are specified, check if user role matches
  if (roles.length > 0 && !roles.includes(authData.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // If authenticated and role is valid, render the component
  return children;
};

export default ProtectedRoute;
