// import React, { useState } from 'react';
// import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText, Divider, CssBaseline, Box } from '@mui/material';
// import Device from './Dashboard';

// const drawerWidth = 240;

// const Dashboard = () => {
//   // Side list items for the drawer
//   const sideList = (
//     <div>
//       <List>
//         {['Dashboard', 'Analytics', 'Reports', 'Settings'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//       <Divider />
//       <List>
//         {['Profile', 'Logout'].map((text, index) => (
//           <ListItem button key={text}>
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}
//       </List>
//     </div>
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       {/* CSS Baseline to ensure consistent styling */}
//       <CssBaseline />

//       {/* Navbar at the top */}
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1, // Keep it above the sidebar
//         }}
//       >
//         <Toolbar>
//           <Typography variant="h6" noWrap component="div">
//             Dashboard
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* Sidebar (Drawer) */}
//       <Drawer
//         variant="permanent"
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           [`& .MuiDrawer-paper`]: {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <Toolbar />
//         {sideList}
//       </Drawer>

//       {/* Main Content Area */}
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           p: 3,
//           width: `calc(100% - ${drawerWidth}px)`,
//           overflowY: 'auto',
//           height: '100vh',
//         }}
//       >
//         {/* Toolbar spacer to push content below the AppBar */}
//         <Toolbar />

// <Device/>

       
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;



// import React, { useState } from 'react';
// import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText, Divider, CssBaseline, Box, IconButton, ListItemIcon } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
// import ReportIcon from '@mui/icons-material/Assessment';
// import SettingsIcon from '@mui/icons-material/Settings';
// import ProfileIcon from '@mui/icons-material/Person';
// import LogoutIcon from '@mui/icons-material/ExitToApp';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import Device from './Dashboard';

// const collapsedDrawerWidth = 60; // Width when sidebar is collapsed
// const expandedDrawerWidth = 240; // Width when sidebar is expanded

// const Dashboard = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   // Side list items for the drawer
//   const sideList = (
//     <List>
//       <ListItem button>
//         <ListItemIcon><DashboardIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Dashboard" />}
//       </ListItem>
//       <ListItem button>
//         <ListItemIcon><AnalyticsIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Recordes" />}
//       </ListItem>
//       <ListItem button>
//         <ListItemIcon><ReportIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Reports" />}
//       </ListItem>
//       <ListItem button>
//         <ListItemIcon><SettingsIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Settings" />}
//       </ListItem>
//     </List>
//   );

//   const bottomList = (
//     <List>
//       <ListItem button>
//         <ListItemIcon><ProfileIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Profile" />}
//       </ListItem>
//       <ListItem button>
//         <ListItemIcon><LogoutIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Logout" />}
//       </ListItem>
//       {/* Toggle button */}
//       <ListItem button onClick={toggleSidebar}>
//         <ListItemIcon><ChevronLeftIcon /></ListItemIcon>
//         {isSidebarOpen && <ListItemText primary="Collapse" />}
//       </ListItem>
//     </List>
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       {/* CSS Baseline to ensure consistent styling */}
//       <CssBaseline />

//       {/* Navbar at the top */}
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1, // Keep it above the sidebar
//         }}
//       >
//         <Toolbar>
//           <Typography variant="h6" noWrap component="div">
//             Dashboard
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* Sidebar (Drawer) */}
//       <Drawer
//         variant="permanent"
//         sx={{
//           width: isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth,
//           flexShrink: 0,
//           transition: 'width 0.3s',
//           [`& .MuiDrawer-paper`]: {
//             width: isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth,
//             boxSizing: 'border-box',
//             transition: 'width 0.3s',
//           },
//         }}
//       >
//         {/* Sidebar Content */}
//         <Toolbar />
//         {sideList}
//         <Divider />
//         {bottomList}
//       </Drawer>

//       {/* Main Content Area */}
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           p: 3,
//           width: `calc(100% - ${isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth}px)`,
//           transition: 'width 0.3s',
//           overflowY: 'auto',
//           height: '100vh',
//         }}
//       >
//       {/* Toolbar spacer to push content below the AppBar */}
//         <Toolbar />
// <Device/>
    
//       </Box>
//     </Box>
//   );
// };

// export default Dashboard;



import React , {useState}from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText, Divider, CssBaseline, Box, IconButton, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ReportIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Device from './Dashboard';
import { useAuth } from '../context/authcontext';  // Import useAuth to access logout function

const collapsedDrawerWidth = 60;
const expandedDrawerWidth = 240;

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { logout } = useAuth();  // Destructure logout function from useAuth hook

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout();  // Call the logout function to clear user session
  };

  const sideList = (
    <List>
      <ListItem button>
        <ListItemIcon><DashboardIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Devices" />}
      </ListItem>
      <ListItem button>
        <ListItemIcon><AnalyticsIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Records" />}
      </ListItem>
      <ListItem button>
        <ListItemIcon><ReportIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Reports" />}
      </ListItem>
      <ListItem button>
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Settings" />}
      </ListItem>
    </List>
  );

  const bottomList = (
    <List>
      <ListItem button>
        <ListItemIcon><ProfileIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Profile" />}
      </ListItem>
      <ListItem button onClick={handleLogout}>  {/* Add onClick handler for logout */}
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Logout" />}
      </ListItem>
      <ListItem button onClick={toggleSidebar}>
        <ListItemIcon><ChevronLeftIcon /></ListItemIcon>
        {isSidebarOpen && <ListItemText primary="Collapse" />}
      </ListItem>
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth,
          flexShrink: 0,
          transition: 'width 0.3s',
          [`& .MuiDrawer-paper`]: {
            width: isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth,
            boxSizing: 'border-box',
            transition: 'width 0.3s',
          },
        }}
      >
        <Toolbar />
        {sideList}
        <Divider />
        {bottomList}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${isSidebarOpen ? expandedDrawerWidth : collapsedDrawerWidth}px)`,
          transition: 'width 0.3s',
          overflowY: 'auto',
          height: '100vh',
        }}
      >
        <Toolbar />
        <Device />
      </Box>
    </Box>
  );
};

export default Dashboard;
