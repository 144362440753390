

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/authcontext.js";
import { Spinner } from "react-bootstrap";
import "./login.css";
import Swal from 'sweetalert2';

const Login = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const isEmail = identifier.includes('@');
      const response = await axios.post(
        "https://backdtracker.supportplus.app/auth/login",
        // "http://localhost:4040/auth/login",
        isEmail ? { email: identifier, password } : { name: identifier, password }
      );
      await login(response.data);  // Trigger login process
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: error.response && error.response.status === 401
          ? 'Invalid credentials. Please check your username or password.'
          : 'An error occurred. Please try again later.',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          content: 'swal2-content',
          confirmButton: 'swal2-confirm',
          cancelButton: 'swal2-cancel'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
     
          <div className="login-box mx-auto">
            <div className="card" style={{ borderRadius: '20px' }}>
              <div className="card-body login-card-body">
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name or Email"
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                      required
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-navy btn-block">
                        {loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Sign In"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-20"></div>
    </div>
  );
};

export default Login;
